
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat } from '@/utils/helper/format';

const store = 'store';
const breadcrumbs = ['Partner Store', ['Store List']];
const tableHeader = [
  {
    key: 'no',
    name: 'No.',
    sortable: true,
  },
  {
    name: 'Store',
    key: 'storeDetail',
    sortable: true,
  },
  {
    name: 'Owner',
    key: 'owner',
    sortable: true,
  },
  {
    name: 'Date Created',
    key: 'createdAt',
    sortable: false,
  },
  {
    name: 'Actions Detail',
    key: 'moreDetail',
    sortable: false,
  },
];

export default {
  name: 'Commission List',
  components: {
    Datatable,
  },
  setup() {
    const tableRef = ref<any>(null);
    const state = reactive({
      tableData: [],
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(state.currentPage, state.rowsPerPage);
      }
    );

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
        };

        const { data } = await ApiService.get(
          `/${store}?${new URLSearchParams(queryCondition)}`
        );
        if (data.status) {
          state.total = data.total;
          state.tableData = data.datas;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );
      await fetch();
    });

    return {
      ...toRefs(state),
      tableHeader,
      breadcrumbs,
      tableRef,
      dateFormat,
    };
  },
};
